.container {
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 1.7fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
    "header"
    "detail-container";
}

.header { grid-area: header; }

.detail-container { grid-area: detail-container; }
