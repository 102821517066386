.footer-info {
    word-wrap: break-word;
    width: 495px;
    text-align: center;
    color: #CB3B34;
    font-size: 45px;
    font-weight: bold;
    text-shadow: 0 0 2px white, 0 0 2px white;
}

.footer-icon {
    display: flex;
    justify-content: center;
}
