.icon {
    position: absolute;
    width: 80px;
    height: 60px;
    margin-top: 90px;
}

.arrow {
    position: absolute;
    transform: rotate(90deg);
    top: 25px;
    width: 90%;
    height: 10px;
    background-color: #CB3B34;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    animation: arrow 700ms linear infinite;
    border-radius: 5px;
}

.arrow::after, .arrow::before {
    content: '';
    position: absolute;
    width: 60%;
    height: 10px;
    right: -8px;
    background-color: #CB3B34;
    border-radius: 5px;
}

.arrow::after {
    top: -12px;
    transform: rotate(45deg);
}

.arrow::before {
    top: 12px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(-45deg);
}
