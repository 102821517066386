.container-detail {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 1.4fr 0.5fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "name-container"
        "property-container"
        "image-container";
}

.name-container {
    grid-area: name-container;
    justify-self: center;
    word-wrap: break-word;
    text-align: center;
    width: 350px;
    color: #CB3B34;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 0 2px white, 0 0 2px white;
}

.property-container {
    justify-self: center;
    align-self: center;
    width: 150px;
    margin-top: 45px;
    border-left: 10px solid #555253;
    padding-left: 20px;
    height: 100%;
}

.ingredients-container {
    margin-top: 20px;
}

.allergens-container {
    margin-top: 20px;
}

.image-container {
    grid-area: image-container;
    display: flex;
    align-self: center;
    justify-self: center;
}

.ingredients-title {
    color: #CB3B34;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 0 1px white, 0 0 1px white;
}

.allergens-title {
    color: #CB3B34;
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 0 1px white, 0 0 1px white;
}

.prop-name {
    margin-top: 5px;
    font-size: 23px;
    white-space: nowrap;
    text-transform: uppercase;
}
