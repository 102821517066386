.container-header {
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 3fr 2fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
    "header"
    "start-animation"
    "footer";
}

.start-animation {
    grid-area: start-animation;
    align-self: center;
    justify-self: center;
}

.header { grid-area: header; }

.footer {
    grid-area: footer;
    justify-self: center;
}
